<!-- eslint-disable no-useless-escape -->
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import Integrations  from "@/components/projects/integrations";
import FeedConfig  from "@/components/projects/feed-config";
import {integrationTypes} from "../../../services/integrations";
import Drawer from "vue-simple-drawer";
import Multiselect from "vue-multiselect"; 
import Prism from 'vue-prismjs'
  import 'prismjs/themes/prism.css'

import {
	integrationMethods
} from "@/state/helpers";

/**
 * Integrations Page
 */

export default {
	page: {
		title: "Integrations",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		EmptyList,
    Integrations,
		FeedConfig,
		Drawer,
		Multiselect,
		Prism
	},
	data() {
		return {
			title: "",
			items: [],
			integrations: [],
      integrationId: null,
      integration:{},
      showModal: false,
      currentPage:1,
      perPage:10,
      rows:0,
      isLoading: false,
      empty_config:{
        "title" : this.$t("integrations.empty_title"),
        "subtitle" : this.$t("integrations.empty_text"),
        "buttonText" : this.$t("integrations.new"),
        icon: "fa-cubes"
      },
      empty_list_config:{
        "title" : this.$t("integrations.empty_search_title"),
        "linkButtonText" : this.$t("common.reset_search"),
        icon: "fa-cubes"
      },
      query:'',
      showIntegrationsAvailable:false,
			confirmDelete: false,
			showEditIntegrationModal:false,
			submitted:false,
			integrationsAvailable: Object.assign([], integrationTypes),
			domain: '',
			field: null,
			openRightDrawer: false,
			providers: [
				{id:'magento', label:"Magento"},
				{id:'vtex', label:"Vtex"},
				{id:'fenicio', label:"Fenicio"},
				{id:'feed_url', label:"Other"},
			],
			selectedProvider: {id:'magento', label:"Magento"},
			code: `<script>
	async function initializeGSSDK() {
		window.gsSDK = await new window.GSSDK.default('${window.config['sdk-domain']}-ZUo9tj2X9xOBxaGJ',
		{provider: '{1}'});
	}
	var gsSDKScript = document.createElement('script');
	gsSDKScript.src = '${window.config['sdk-url']}';
	gsSDKScript.onload = initializeGSSDK;
	document.head.appendChild(gsSDKScript);
<\/script>`,
		startCode: `<script>
	async function initializeGSSDK() {
		window.gsSDK = await new window.GSSDK.default('${window.config['sdk-domain']}-{2}',
		{provider: '{1}'});
	}
	var gsSDKScript = document.createElement('script');
	gsSDKScript.src = '${window.config['sdk-url']}';
	gsSDKScript.onload = initializeGSSDK;
	document.head.appendChild(gsSDKScript);
<\/script>`	
		};
	},
	mounted() {
		this.loadIntegrations();
		
	},
	created(){
		this.debounceIntegrations = _debounce(this.searchIntegrations.bind(this), 1000);
	},
	methods: {
    ...integrationMethods,
		
		loadIntegrations(){
			let loader = this.$loading.show();
			const params={
				q: `where[project]=${localStorage.getItem('current_project')}`,
			}
			this.getIntegrations(params).then((integrations)=>{
				if(integrations&& integrations.data){
					this.integrations= integrations.data;
          this.rows = integrations.totalCount;
        }
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t('integrations.get_integrations_error') ,title:  this.$t('integrations.title') });
			}).finally(()=>{
        if(!this.isLoading)
          this.isLoading = true;
				loader.hide();
			});
		},

		onDeleteIntegrationClicked(integration){
				this.confirmDelete = true;
				const cloneIntegration = Object.assign({}, integration);

				this.integration = cloneIntegration;
		},

		onEditIntegrationClicked(integration){
			const cloneIntegration = {...integration};
				
			this.integration = cloneIntegration;
			this.parseFieldsFromData();

			if(this.integration.type== 'feed_url' || this.integration.group == 'feed'){
				let loader = this.$loading.show();
				setTimeout(()=>{
					this.$refs.feedConfigs.setFeedConfigSettings(integration.data);
					loader.hide();
				},1000);
			}
			this.showEditIntegrationModal= true;
		},

		onConfirmRemoveIntegrationClicked(){
				let loader = this.$loading.show();

				if(this.integration.group == 'go_shops'){
					this.deleteGoShopsIntegration(this.integration.data.authId).then(()=>{
						this.deleteIntegration(this.integration._id).then(()=>{
							this.$notify({ type: 'success', text: this.$t('integrations.delete_integration_success'),title:  this.$t('integrations.title') });
							this.currentPage=1;
							this.loadIntegrations();
						});
					}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t('integrations.delete_integration_error'),title:  this.$t('integrations.title') });		
				}).finally(()=>{
						this.isLoading = false;
						loader.hide();
						this.integration=null;
				})
				}else{
					this.deleteIntegration(this.integration._id).then(()=>{
						this.$notify({ type: 'success', text: this.$t('integrations.delete_integration_success'),title:  this.$t('integrations.title') });
						this.currentPage=1;
						this.loadIntegrations();
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t('integrations.delete_integration_error'),title:  this.$t('integrations.title') });		
				}).finally(()=>{
						this.isLoading = false;
						loader.hide();
						this.integration=null;
				})
				}
				
		},

		onIntegrationListPageClicked() {
				this.loadIntegrations();
		},

		onCancelCreateOrEditIntegrationClicked() {
				this.showModal = false;
				this.integration = null;
				this.integrationId = null;
		},

		searchIntegrations(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("integrations.empty_search_text").replaceAll('#', this.query);

			if(query){
				this.currentPage = 1;
						const params={
							q: `where[project]=${localStorage.getItem('current_project')}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
						}
						this.getIntegrations(params).then((integrations)=>{
							if(integrations&& integrations.data){
								this.integrations= integrations.data;
								this.rows= integrations.totalCount;
							}
				}).catch(()=>{
							this.$notify({ type: 'error', text: this.$t('integrations.get_integrations_error'),title: this.$t('integrations.title') });	
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
			}else{
        this.query=''
				this.isLoading = false;
				loader.hide();
				this.currentPage = 1;
				this.loadIntegrations();
			}
		},
    onNewIntegrationClicked(){
			this.integration = {}
      this.showIntegrationsAvailable = true;
			
    },
    onResetSearchClicked(){
      this.query=''
      this.loadIntegrations();
    },
		onConfirmNewIntegration(){
			this.showIntegrationsAvailable = false;
			this.query=''
			this.currentPage = 1;
			this.loadIntegrations();
		},
		validateIntegrationFields(){
			for (let i = 0; i< this.integration.fields.length; i++){
				if( this.integration.fields[i].type == 'checkbox' && this.integration.fields[i].value == null){
          this.integration.fields[i].value = false
        }

        if(this.integration.fields[i].value == null || this.integration.fields[i].value === '' ){
					return false;
				}
          
      }
      return true;
    },
		onConfirmEditIntegrationClicked(){
			this.submitted = true;

      if(!this.validateIntegrationFields())
        return;
      
			if(this.integration.type=='feed_url'){
				if(!this.$refs.feedConfigs.validateFeedConfigSettings())
					return;
			}

			const loader = this.$loading.show();

			const int = {
        _id: this.integration._id,
				data: {...this.parseDataFromFields()}
      }

			if(this.integration.group == 'go_shops'){
				int.data.authId = this.integration.data.authId;
				int.data.clientId = this.integration.data.clientId;
				const authChannel = {
					_id: int.data.authId,
           name: int.data['name'],
           sessionExpirationTime: int.data.sessionExpirationTime
        }

				if(this.integration.type == 'go_shops_web'){
					int.data.domains = this.integration.data.domains;
					authChannel.channelDomain = int.data.domains
				}

				this.updateGoShopsIntegration(authChannel).then(()=>{
					this.updateIntegration(int).then(()=>{
						this.submitted = false;
						this.$notify({ type: 'success', text: this.$t("integrations.update_integration_success"),title:  this.$t('integrations.title') });
						this.showEditIntegrationModal =false;
						this.currentPage = 1;
						this.loadIntegrations();
					}).catch(()=>{})
				}).catch(()=>{
						this.$notify({ type: 'error', text: this.$t('integrations.update_integration_error'),title:  this.$t('integrations.title') });
					}).finally(()=>{
						loader.hide()
					})

			}else{

				if(this.integration.type=='feed_url' || this.integration.group == 'feed'){
					int.data = { ...this.$refs.feedConfigs.getConfig(), ...int.data } ; 
        }
				
				this.updateIntegration(int).then(()=>{
					this.submitted = false;
					this.$notify({ type: 'success', text: this.$t('integrations.update_integration_success'),title:  this.$t('integrations.title') });
					this.showEditIntegrationModal =false;
					this.currentPage = 1;
					this.loadIntegrations();
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t('integrations.update_integration_error'),title:  this.$t('integrations.title') });
				}).finally(()=>{
					loader.hide()
				})
			}	
      
		},
		parseFieldsFromData(){
			const fields = this.integrationsAvailable.filter(t=> t.name == this.integration.name)[0].fields;
			const cloneFields = Object.assign([], fields);
			cloneFields.forEach((f)=>{
				f.value = this.integration.data[f.key] || null;
			})

			this.integration.fields = cloneFields;
		},
		parseDataFromFields(){
      const data = {};
      this.integration.fields.forEach((f)=>{
        data[f.key] = f.value;
      })
			return data;
    },

		onAddDomainClicked(){
			if(!this.integration.data.domains)
				this.integration.data.domains = [];

			const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
			if(this.domain && domainRegex.test(this.domain)){
				this.integration.data.domains.push(this.domain);
				this.domain = ''
			}
		},
		onRemoveDomain(index){
			this.integration.data.domains.splice(index,1)
		},
		onCopyValueClicked(){
      navigator.clipboard.writeText(this.integration.data.clientId);
    },
		onCopyScriptCodeClicked(){
      navigator.clipboard.writeText(this.code);
    },
		onGoBotIntegrationClicked(integration){
			const loader = this.$loading.show();
			this.ssoGoShopsIntegration(integration._id).then((res)=>{
				if(res?.url){
					window.open(res.url, '_blank');
				}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t('integrations.update_integration_error'),title:  this.$t('integrations.title') });
			}).finally(()=>{
				loader.hide();
			})
		},
		onNewFileClicked(field){
			this.field = field;
			document.getElementById('dropzoneFile').click()

		},
		onNewFileSelected(){
			if(document.getElementById('dropzoneFile').files.length ==0)
				return;

			var file = document.getElementById('dropzoneFile').files[0];

			if(this.field.format == 'application/json'){
        var reader = new FileReader();
        reader.onload = (event)=>{
          var fileObj = JSON.parse(event.target.result);
          this.field.value = fileObj;
					
					if(this.integration){
            const index = this.integration.fields.findIndex(f=> f.name == this.field.name);
            this.$set(this.integration.fields, index, this.field);
					}
        };
        reader.readAsText(file);
      }
		},
		rightDrawer() {
      this.openRightDrawer = !this.openRightDrawer;
    },
		loadFeedTypeIntegration(){
			let loader = this.$loading.show();
			const params={
				q: `where[project]=${localStorage.getItem('current_project')}&where[group]=feed`,
			}
			this.getIntegrations(params).then((inte)=>{
				if(inte&& inte.data && inte.data.length > 0){
					this.selectedProvider = this.providers.filter(p=>p.id==inte.data[0].type).length > 0? this.providers.filter(p=>p.id==inte.data[0].type)[0] : this.selectedProvider;
				}
				this.openRightDrawer = true;
				this.showEditIntegrationModal = false;
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t('integrations.get_integrations_error') ,title:  this.$t('integrations.title') });
			}).finally(()=>{
        if(!this.isLoading)
          this.isLoading = true;
				loader.hide();
			});
		},
		onEmbedScriptClicked(){
			this.loadFeedTypeIntegration();
		}
	},
	watch:{
		selectedProvider:{
			immediate:true,
			handler(newVal){
				if(newVal){
					this.code = this.startCode.replace('{1}',newVal.label).replace('{2}',this.integration.data.clientId)
				}
			}
		}
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('integrations.title')" :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row ">
							<div class="col-sm-4">
								<div class="search-box me-2 mb-0 d-inline-block">
									<div class="position-relative">
										<input
										type="text"
										class="form-control"
										:placeholder="$t('common.search')"
										@input="debounceIntegrations($event.target.value)"
                    v-model="query"
										/>
										<i class="bx bx-search-alt search-icon"></i>
									</div>
								</div>
							</div>
							<div class="col-sm-8">
								<div class="text-sm-end">
									<button
											type="button"
											class="btn btn-primary mb-0 me-0"
											@click="onNewIntegrationClicked"
											v-if="integrations.length>0 || query!=''">
											<i class="mdi mdi-plus me-1"></i> {{$t("integrations.new")}}
									</button>
								</div>
							</div>
						<!-- end col-->
						</div>
				</div>
        <EmptyList :config="empty_config" v-if="isLoading && integrations.length == 0" @onButtonClicked="onNewIntegrationClicked" class="mt-3 mb-3"/>
        <EmptyList :config="empty_list_config" v-if="!isLoading && integrations.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
				<div class="table-responsive" v-if="integrations.length > 0">
					<table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
						<thead class="table-light">
							<tr>
								<th scope="col" style="width: 70px;"></th>
								<th scope="col">{{$t("integrations.name")}}</th>
								<th scope="col">{{$t("integrations.type")}}</th>
								<th scope="col" class="text-end"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="inte in integrations" :key="inte._id">
								<td>
                  <div class="favorite-icon">
                    <img :src="inte.img" height="50">
                  </div>
                </td>
								<td @click="onEditIntegrationClicked(inte)">
									<h5 class="font-size-14 mb-1">
										<a href="#" class="text-dark">{{inte.group!= 'go_shops' ? inte.name : inte.data.name}}</a>
									</h5>
								</td>
								<td>{{ inte.group!= 'go_shops' ?  $t(`integrations.group_${inte.group}`): $t(`integrations.group_${inte.type}`)}}</td>
								<td class="text-end">
									<i class="fas fa-robot text-primary me-2 interact" v-if="inte.type== 'go_chatbot'" v-on:click="onGoBotIntegrationClicked(inte)"></i>
									<i class="fas fa-edit text-success me-2 interact" v-on:click="onEditIntegrationClicked(inte)"></i>
									<i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onDeleteIntegrationClicked(inte)"></i>
								</td>
							</tr>
						</tbody>
					</table>
					<b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveIntegrationClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
							<p>{{$t('integrations.remove_integration')}}</p>
					</b-modal>
          </div>
          <b-modal id="integration-modal" size="xl" v-model="showIntegrationsAvailable" title="Add Integration" hide-footer>
            <Integrations @confirmAddIntegration="onConfirmNewIntegration" :integrationsAdded="integrations" />
          </b-modal>
					<b-modal  v-model="showEditIntegrationModal" :title="integration?.group!= 'go_shops' ? integration?.name : $t(`integrations.group_${integration?.type}`)" @ok.prevent="onConfirmEditIntegrationClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
						<div v-if="integration?.type=='feed_url'">
							<FeedConfig ref="feedConfigs"/>
						</div>
						<div v-else-if="integration?.group == 'feed'">
							<b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
								<b-tab active>
									<template v-slot:title>
										<span class="d-inline-block d-sm-none">
											<i class="bx bx-cog"></i>
										</span>
										<span class="d-none d-sm-inline-block">  <i class="bx bx-cog"></i> General</span>
									</template>
									<div class="row">
										<div v-for="field in integration?.fields" :key="field.name" class="mb-3">
											<label class="mb-0">{{field.name}}</label>
											<DropZone @drop.prevent="drop" v-if="field.type=='file' && !field.value" ref="dropZone" :accept="field.format || ''" @onFile="onDropFileZoneSelected" :field="field" />
											<!--<input :type="field.type" class="form-control" v-model="field.value" :accept="'.json'" :class="{'is-invalid':submitted && (!field.value || field.value==''),}" v-if="field.type=='file'"/> -->
											<input :type="field.type" class="form-control" v-model="field.value" :class="{'is-invalid':submitted && (!field.value || field.value==''),}" v-if="field.type!='checkbox' && field.type!='file'"/>
											<div class='form-check form-switch form-switch-lg' v-if="field.type=='checkbox'">
													<input
														class="form-check-input"
														type="checkbox"
														v-model="field.value"
													/>
												</div>
											<div v-if="submitted && (!field.value || field.value=='')" class="invalid-feedback">
												{{$t('integrations.field_requiered')}}
											</div> 
										</div>
									</div>
								</b-tab>
								<b-tab>
									<template v-slot:title>
										<span class="d-inline-block d-sm-none">
											<i class="bx bx-stopwatch"></i>
										</span>
										<span class="d-none d-sm-inline-block">  <i class="bx bx-stopwatch"></i> {{$t('integrations.feed_sync')}}</span>
									</template>
									<FeedConfig ref="feedConfigs" :type="integration?.type" :integration="integrationsAvailable.filter(i=>i.type == integration?.type).length > 0 ? integrationsAvailable.filter(i=>i.type == integration?.type)[0] : null" class="mt-2" />
								</b-tab>
							</b-tabs>
						</div>
						<div v-else>
							<div v-for="field in integration?.fields" :key="field.name" class="mb-3">
								<label class="mb-0">{{field.name}}</label>
								<input :type="field.type" class="form-control" v-model="field.value" :class="{'is-invalid':submitted && (!field.value || field.value==''),}" v-if="field.type!='checkbox' && field.type!='file'"/>
								<div v-if="field.type == 'file'">
									<label class="font-size-12"> <i class="bx bx-info-circle"/>{{$t('common.already_uploaded')}}</label> <button class="btn btn-sm btn-primary" @click="onNewFileClicked(field)"><i class="bx bx-upload"/> {{$t('common.upload_new')}}</button>
									<input ref="dropzoneFile" type="file" id="dropzoneFile" class="dropzoneFile btn btn-primary" :accept="field.format || ''" @input="onNewFileSelected" style="visibility: hidden;" />
								</div>
								<div class='form-check form-switch form-switch-lg' v-if="field.type=='checkbox'">
										<input
											class="form-check-input"
											type="checkbox"
											v-model="field.value"
										/>
									</div>
								<div v-if="submitted && (!field.value || field.value=='')" class="invalid-feedback">
									{{$t("integrations.field_requiered")}}
								</div> 
							</div>
							<div v-if="integration?.group=='go_shops'" class="mb-3">
								<label class="mb-0">ClientId</label>
								<b-input-group>
									<b-form-input v-model="integration.data.clientId" type="text" disabled></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="onCopyValueClicked()"><i class="bx bx-copy"></i></b-button>
										</b-input-group-append>
								</b-input-group>
							</div>
							<div v-if="integration?.group=='go_shops' && integration?.type == 'go_shops_web'">
								<label class="mb-0">{{$t("integrations.domains")}}</label>
								<div class="d-flex-inline input-group">
									<b-form-input placeholder="Add domain" class="form-control" type="url" v-model="domain"></b-form-input>
									<button class="btn btn btn-outline-primary btn-md" @click="onAddDomainClicked"> <i class="mdi mdi-plus"></i></button>
								</div>
								<div v-if="integration?.data?.domains?.length > 0" class="row mt-2">
									<div class="col">
										<table class="table table-sm align-middle table-nowrap table-hover mt-1 pe-2 ps-2">
											<thead class="table-light">
												<tr>
													<th scope="col">{{$t("integrations.domain")}}</th>
													<th scope="col" style="width: 20px;"></th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(dom,index) in integration?.data?.domains" :key="dom">
														<td>{{dom}}</td>
														<td class="text-end">
																<i class="fas fa-trash-alt text-danger me-2 interact" v-on:click="onRemoveDomain(index)"></i>
														</td>
												</tr>
											</tbody>
									</table>
									</div>
								</div>
								<button class="btn btn-sm btn-link mt-2 ps-0" @click="onEmbedScriptClicked()"><i class='bx bx-code'></i> Embed script</button>
							</div>
						</div>
					</b-modal>
				</div>
				<Drawer
					@close="rightDrawer"
					:align="'right'"
					:closeable="true"
					:maskClosable="true"
					:zIndex="1002">
					<div v-if="openRightDrawer">
						<div class="offcanvas-header">
							<h4 class="offcanvas-title" id="offcanvasExampleLabel">
								<i class="bx bx-code font-size-16 align-middle me-1"></i>
								Script
							</h4>
						</div>
						<hr class="mt-1" />
						<div class="offcanvas-body">
							<label>{{$t('integrations.provider')}}*</label>
							<multiselect 
								v-model="selectedProvider"
								:options="providers"
								:showNoResults="false"
								:show-labels="false"
								track-by="id" 
								label="label"
								:multiple="false"
								class="mb-2">
							</multiselect>

							<prism language="javascript" :code="code"></prism>
							<button class="btn btn-sm btn-link mt-2 ps-0" @click="onCopyScriptCodeClicked()"><i class='bx bx-copy'></i> {{ $t('integrations.copy') }}</button>
						</div>
					</div>
				</Drawer>
				<div class="row mb-4" v-if="rows > perPage">
					<div class="col">
						<div class="dataTables_paginate paging_simple_numbers float-end">
							<ul class="pagination pagination-rounded mb-0">
								<b-pagination
									@input="onIntegrationListPageClicked"
									v-model="currentPage"
									:total-rows="rows"
									:per-page="perPage">
								</b-pagination>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<style>
.interact{
    cursor: pointer;
}

#integration-modal .modal-body {
  background-color: var(--bs-body-bg);
}
</style>